<template>
  <ValidationObserver
    ref="observer"
    class="column box is-half"
  >
    <form
      @submit.prevent="
        updateregistration({
          step: 'password',
          registrationdata: {
            password: registrationClientData.password,
          },
        })
      "
    >
      <fieldset :disabled="stepsDone.password">
        <BInputWithValidation
          v-model.trim="registrationClientData.password"
          vid="password"
          name="password"
          :rules="'required' + `${isProduction ? '|min:8' : ''}`"
          type="password"
          label="Wachtwoord"
          icon="key"
          :success="stepsDone.password"
          password-reveal
        />
      </fieldset>

      <fieldset :disabled="stepsDone.password">
        <BInputWithValidation
          v-model.trim="registrationClientData.repeatPassword"
          vid="any"
          rules="required|confirmed:password"
          type="password"
          label="Herhaal wachtwoord"
          icon="key"
          buttontext="Verzenden"
          :success="stepsDone.password"
          expanded-input
        />
      </fieldset>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import BInputWithValidation from "../../components/inputs/BInputWithValidation";

export default {
   name: "Passwordregistration",

  components: {
    ValidationObserver,
    BInputWithValidation,
    // registrationN,
    // Loader
  },
   computed: {
    ...mapGetters([
      "stepsDone",
      "registrationClientData",
      "isProduction",
    ]),

   },
  methods: {
  ...mapActions(["updateregistration"]),
  }
}
</script>